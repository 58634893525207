import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { injectIntl } from 'helpers'
import { FormattedMessage, Layout, Section, Container, Link } from 'components'

const BlogTemplate = ({
  data: { allContentfulBlog },
  intl: { locale },
  ...props
}) => {
  const post = allContentfulBlog.edges.filter(
    ({ node: { node_locale } }) => node_locale === locale
  )[0].node
  return (
    <Layout>
      <Section>
        <Container>
          <h1>
            <FormattedMessage id="blog" />
          </h1>
          <div
            className="content-html"
            dangerouslySetInnerHTML={{
              __html: post.content.childMarkdownRemark.html,
            }}
          />
          <hr />
          <code>{JSON.stringify(post)}</code>
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(BlogTemplate)

export const query = graphql`
  query($slug: String!) {
    allContentfulBlog(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          content {
            content
            childMarkdownRemark {
              html
            }
          }
          node_locale
        }
      }
    }
  }
`
